.site-list{
    position: relative;
    margin: 0 0 0 $pc-head;
    padding: 80px;
    padding-right: 40px;
    @media screen and (max-width: $tab){
        padding: 40px;
        padding-right: 20px;
    }
    @media screen and (max-width: $phone){
        margin: $sp-head 0 0;
        padding: 20px;
    }
    .listable{
        @include flex();
        align-items: flex-start;
        justify-content: flex-start;
        &__item{
            width: 260px;
            background: #FFF;
            padding: 40px 24px 24px;
            border-radius: 4px;
            text-align: center;
            position: relative;
            margin: 0 32px 32px 0;
            @media screen and (max-width: $tab){
                width: 230px;
                margin: 0 20px 20px 0;
            }
            @media screen and (max-width: $phone){
                width: 48.5%;
                margin: 0 3% 3% 0;
                padding: 15px;
                padding-top: 24px;
                &:nth-child(2n+2){
                    margin-right: 0;
                }
            }
            &.disable{
                background: darken($gray, 4%);
                color: lighten($normal, 40%);
                .add,
                .view{
                    background: darken($gray, 8%);
                    color: lighten($normal, 40%);
                    &:hover{
                        background: $white;
                        color: $normal;
                    }
                }
            }
            .badge{
                position: absolute;
                top: -8px;
                right: -8px;
                width: 44px;
                height: 44px;
                font-size: 1.2rem;
                background: $normal;
                color: $white;
                border-radius: 50%;
                @include flex();
                align-items: center;
                justify-content: center;
                transform: rotate(16.6deg);
                z-index: 2;
                @media screen and (max-width: $phone){
                    width: 32px;
                    height: 32px;
                    top: 8px;
                    right: 8px;
                    font-size: 1.08rem;
                }
            }
            > dl{
                margin: 0 0 24px;
                @media screen and (max-width: $phone){
                    margin: 0 0 16px;
                }
                dt{
                    font-size: 4rem;
                    line-height: 1;
                    @media screen and (max-width: $phone){
                        font-size: 3rem;
                    }
                }
                dd{
                    font-size: 1.12rem;
                    font-weight: 700;
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    @media screen and (max-width: $phone){
                        font-size: .96rem;
                    }
                }
            }
            .add,
            .view{
                background: darken($gray, 1%);
                color: $normal;
                padding: 4px 0;
                border-radius: 4px;
                cursor: pointer;
                margin: 0 0 12px;
                &:last-child{
                    margin: 0;
                }
                &:hover{
                    color: $white;
                    background: $normal;
                }
            }
        }
    }
}
