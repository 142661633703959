@charset "utf-8";

@import "module/reset";
@import "module/module";

//USE ASSETS
@import "plugin/swiper";

/* BASE COLOR
------------------------------------------------------------*/
$normal:#2e2e37;
$link:#D02;
$border:#e8e8eb;
$gray: #f0f0f3;
$white: #fefeff;
$ease: cubic-bezier(0.65, 0, 0.35, 1);

/* SITE SETTING
------------------------------------------------------------*/
$width: 1120px;
$tab: $width - 1px;
$phone: 640px;

$pc-head: 240px;
$sp-head: 96px;

:root{
	font-size: 14.5px;
    @media screen and (max-width: $tab){
        font-size: 14px;
    }
    @media screen and (max-width: $phone){
        font-size: 13px;
    }
}

@media screen and (min-width: $width){
	@include default(
		$width, //サイト幅
		1rem, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		$gray //ボディ背景
	);
}

@media screen and (max-width: $tab){
	@include default(
		auto, //サイト幅
		1rem, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		$gray //ボディ背景
	);
}

@media screen and (max-width: $phone){
	@include default(
		auto, //サイト幅
		1rem, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		$gray //ボディ背景
	);
}

@import "module/wire";
@import "common/header";
@import "common/footer";
@import "page/list";

#site-wrapper,#site-footer{

}

.site-module{
    input[type=text],
    input[type=email],
    input[type=password],
    input[type=submit],
    input[type=button],
    select,
    textarea{
        @include font();
        font-size: 1.12rem;
        background: $gray;
        padding: 6px 10px;
        border: $gray 2px solid;
        border-radius: 4px;
        width: 100%;
        display: block;
        outline: none;
        &:focus{
            border-color: $normal;
            background: $white;
        }
    }
    textarea{
        height: 120px;
    }
    input[type=submit],
    input[type=button]{
        border-color: $normal;
        background: $normal;
        color: $white;
        padding: 10px 32px;
        width: auto;
        display: inline-block;
        cursor: pointer;
        .disable{
            pointer-events: none;
            cursor: auto;
            background: $gray;
            color: $normal;
        }
        @media screen and (max-width: $phone){
            display: block;
            width: 100%;
            text-align: center;
        }
        &:hover,
        &:focus{
            background: $white;
            color: $normal;
        }
    }
    .goday{
        position: fixed;
        top: 80px;
        right: 0;
        z-index: 1000;
        background: $normal;
        color: $white;
        padding: 20px 12px;
        border-radius: 4px 0 0 4px;
        @media screen and (min-width: $tab + 1px){
            -webkit-writing-mode: vertical-rl;
            -moz-writing-mode: vertical-rl;
            -ms-writing-mode: tb-rl;
            writing-mode: vertical-rl;
        }
        @media screen and (max-width: $tab){
            padding: 16px 8px;
        }
        @media screen and (max-width: $phone){
            top: auto;
            bottom: 12px;
            right: 12px;
            border-radius: 4px;
            padding: 12px 20px;
        }
    }
    .overlay{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 9999;
        background: rgba($normal, .88);
        &__box{
            background: $white;
            border-radius: 4px;
            padding: 40px;
            text-align: left;
            color: $normal;
            width: 640px;
            max-height: 88vh;
            overflow: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            @media screen and (max-width: $phone){
                width: calc(100% - 40px);
                max-height: calc(100% - 40px);
                padding: 24px;
                padding-top: 32px;
            }
            &__close{
                position: absolute;
                top: 24px;
                right: 24px;
                z-index: 2;
                width: 32px;
                height: 32px;
                cursor: pointer;
                @media screen and (max-width: $phone){
                    width: 30px;
                    height: 30px;
                }
                &:before,
                &:after{
                    content: '';
                    width: 100%;
                    height: 2px;
                    border-radius: 999px;
                    background: $normal;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    border-radius: 999px;
                    transform: translate(-50%, -50%) rotate(45deg);
                    transition: transform 120ms $ease;
                }
                &:after{
                    transform: translate(-50%, -50%) rotate(-45deg);
                }
                &:hover{
                    &:before,
                    &:after{
                        transform: translate(-50%, -50%) rotate(0) scaleX(.8);
                    }
                }
            }
            h3{
                font-size: 1.56rem;
                line-height: 1;
                margin: 0 0 64px;
                @media screen and (max-width: $phone){
                    margin: 0 0 32px;
                }
            }
            .alert{
                margin: 16px 0 0;
            }
            .loading{
                position: absolute;
                right: 24px;
                bottom: 24px;
                width: 24px;
                height: 24px;
                border-radius: 50%;
                border-top: $normal 2px solid;
                animation: load 400ms ease-in-out infinite;
                @keyframes load {
                    0%{
                        transform: rotate(0);
                    }
                    100%{
                        transform: rotate(360deg);
                    }
                }
            }
            dl{
                @include flex();
                align-items: center;
                margin: 0 0 24px;
                padding: 0 0 24px;
                border-bottom: $border 1px solid;
                @media screen and (max-width: $phone){
                    display: block;
                }
                dt{
                    width: 140px;
                    font-size: 1.2rem;
                    @media screen and (max-width: $phone){
                        width: auto;
                        margin: 0 0 6px;
                    }
                }
                dd{
                    width: calc(100% - 140px);
                    @media screen and (max-width: $phone){
                        width: auto;
                    }
                }
                .clum{
                    @include flex();
                    align-items: center;
                    justify-content: flex-start;
                    label{
                        display: block;
                        padding: 6px 16px;
                        background: $gray;
                        border-radius: 4px;
                        margin: 0 12px 0 0;
                        cursor: pointer;
                        &:hover{
                            background: darken($gray, 4%);
                        }
                        &:last-of-type{
                            margin-right: 0;
                        }
                        input{
                            display: inline-block;
                            margin: 0 8px 0 0;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}
