#site-header{
    width: $pc-head;
    height: 100%;
    position: fixed;
    background: $white;
    padding: 12.5vh 40px;
    top: 0;
    left: 0;
    text-align: center;
    @include flex();
    align-items: center;
    z-index: 1000;
    @media screen and (max-width: $phone){
        width: 100%;
        height: $sp-head;
        padding: 0 20px;
        display: block;
        .box{
            @include flex();
            align-items: center;
            justify-content: flex-start;
            height: $sp-head - 40px;
            position: relative;
        }
    }
    h1{
        font-size: 4.4rem;
        letter-spacing: 0;
        line-height: 1;
        white-space: nowrap;
        @media screen and (max-width: $phone){
            font-size: 1.08rem;
            margin: 0 8px 0 0;
        }
    }
    p.context{
        font-size: 1.08rem;
    }
    .nav{
        margin: 4vmin 0 0;
        @media screen and (max-width: $phone){
            margin: 0;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
        }
        &__list{
            li{
                margin: 0 0 12px;
                @media screen and (max-width: $phone){
                    margin: 0 8px 0 0;
                }
                &:last-child{
                    margin: 0;
                }
                a{
                    display: block;
                    border: $normal 2px solid;
                    color: $normal;
                    padding: 4px 0;
                    border-radius: 4px;
                    @media screen and (max-width: $phone){
                        padding: 2px 12px;
                    }
                    &:hover{
                        color: $white;
                        background: $normal;
                    }
                }
            }
        }
    }
    .month{
        margin: 4vmin 0 0;
        @media screen and (max-width: $phone){
            margin: 0;
            position: absolute;
            left: 0;
            bottom: -40px;
            width: 100%;
            overflow: scroll;
            transform: translateY(-10px);
        }
        &__list{
            @include flex();
            margin: 0 0 0 -8px;
            @media screen and (max-width: $phone){
                width: 240%;
            }
            li{
                width: calc(33.3%);
                padding: 0 0 0 8px;
                margin: 8px 0 0;
                @media screen and (max-width: $phone){
                    width: calc(100% / 12);
                    margin: 0;
                }
                &:nth-child(-n+3){
                    margin-top: 0;
                }
                a{
                    display: block;
                    color: $normal;
                    padding: 8px 0;
                    border-radius: 4px;
                    position: relative;
                    cursor: pointer;
                    @media screen and (max-width: $phone){
                        border: $gray 2px solid;
                        padding: 2px 0;
                    }
                    &:hover{
                        background: $normal;
                        color: $white;
                        @media screen and (max-width: $phone){
                            border-color: $normal;
                        }
                    }
                }
                &.active{
                    a{
                        background: darken($gray, 1%);
                        @media screen and (max-width: $phone){
                            border-color: darken($gray, 1%);
                            background: darken($gray, 1%);
                        }
                        &:hover{
                            color: $normal;
                        }
                    }
                }
            }
        }
    }
}
